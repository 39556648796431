import { useEffect, useState } from "react";
import {
  Group,
  Box,
  Collapse,
  ThemeIcon,
  Text,
  UnstyledButton,
  createStyles,
  Button,
  Menu,
} from "@mantine/core";
import {
  IconCalendarStats,
  IconChevronLeft,
  IconChevronRight,
  mmy,
} from "@tabler/icons";
import { Navigate, Link, useNavigate } from "react-router-dom";
import useSelectedPage from "../../hooks/useSelectedPage";
import { useDisclosure } from "@mantine/hooks";
import "./styless.css";
const useStyles = createStyles((theme) => ({
  control: {
    fontWeight: 500,
    display: "block",
    width: "100%",
    padding: `8px`,
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
    fontSize: theme.fontSizes.sm,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[7]
          : theme.colors.gray[0],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
    },
  },

  link: {
    fontWeight: 500,
    display: "block",
    textDecoration: "none",
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    paddingLeft: 31,
    marginLeft: 30,
    fontSize: theme.fontSizes.sm,
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : "",
    borderLeft: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[7]
          : theme.colors.gray[0],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
    },
  },
  linkparent: {
    fontWeight: 500,
    display: "block",
    fontSize: theme.fontSizes.md,
    textDecoration: "none",

    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : " ",
    "&:hover": {
      // backgroundColor:
      //   theme.colorScheme === "dark"
      //     ? theme.colors.dark[7]
      //     : theme.colors.gray[0],
      // color: theme.colorScheme === "dark" ? theme.white : "blue ",
    },
  },

  chevron: {
    transition: "transform 200ms ease",
  },
}));

const CollapsibleItem = ({ label, children }) => {
  const [opened, setOpened] = useState(false);
  return (
    <div>
      <Group position="apart" onClick={() => setOpened((prev) => !prev)}>
        <Text>{label}</Text>
        <ThemeIcon variant="light" size="sm">
          {opened ? "-" : "+"}
        </ThemeIcon>
      </Group>
      <Collapse in={opened}>{children}</Collapse>
    </div>
  );
};

const SidebarItem = ({ link, currentPage, updateCurrentPage }) => {
  const [open, setOpen] = useState(false);
  const { classes, theme } = useStyles();
  const ChevronIcon = theme.dir === "ltr" ? IconChevronRight : IconChevronLeft;
  const navigate = useNavigate();
  console.log(link?.links, "i am here", link?.label);
  if (link?.links?.length > 0) {
    console.log(currentPage, "currpage1");
    return (
      <Menu position="right-start">
        {/* <Menu.Target>
          <Button style={{ width: "80%" }}>{link?.label}</Button>
        </Menu.Target> */}
        {/* <Menu.Dropdown> */}
          <div
            style={{
              color: "black",
              // height: "100px",
              // backgroundColor: "red",
              position: "relative",
              overflow: "visible",
              zIndex: "1",
              marginLeft: "10px",
              borderLeft: "1px solid black",
              marginTop: "5px",
              marginBottom: "5px",
            }}
            className={open ? "sidebar-item open" : "sidebar-item"}
          >
            <div
              className="sidebar-title"
              style={{}}
              onClick={() => {
                setOpen(!open);
                updateCurrentPage(link?.label);
              }}
            >
              <div
                style={{
                  fontSize: "1rem",
                  color: currentPage === link?.label ? "blue" : "black",
                  // backgroundColor: currentPage === link?.label ? "gray" : "",
                }}
              >
                <div
                  style={
                    {
                      // backgroundColor: currentPage === link?.label ? "gray" : "",
                      // padding: "1rem",
                    }
                  }
                >
                  {link?.label}
                </div>
              </div>

              <ChevronIcon
                className={classes.chevron}
                size={14}
                stroke={1.5}
                style={{
                  transform: open
                    ? `rotate(${theme.dir === "rtl" ? -90 : 90}deg)`
                    : "none",
                }}
              />
            </div>
            <div className="sidebar-content">
              {link?.links.map((child, index) => (
                <SidebarItem
                  key={index}
                  link={child}
                  updateCurrentPage={updateCurrentPage}
                  currentPage={currentPage}
                />
              ))}
            </div>
          </div>
        {/* </Menu.Dropdown> */}
      </Menu>
    );
  } else {
    console.log(currentPage, "currpage2");
    return (
      // <Menu position="right-start">
      <div
        onClick={() => {
          navigate(link?.link);
          console.log(link?.label, "herer");
          updateCurrentPage(link?.label);
        }}
        style={{
          borderLeft: "1px solid black",
          marginTop: "5px",
          marginBottom: "5px",
          marginLeft: "10px",
          color: currentPage === link.label ? "blue" : "black",
        }}
        className="sidebar-item plain"
      >
        {link?.label}
      </div>
    );
  }
};

export default function LinksGroup({
  icon: Icon,
  label,
  link,
  image,
  initiallyOpened,
  links,
  selected,
  activeItem,
  setItem,
  currentPage,
  currentPage2,
  updateCurrentPage,
  updateCurrentPage2,
  image2,
}) {
  const { classes, theme } = useStyles();
  const hasLinks = Array.isArray(links);
  const [opened, setOpened] = useState(initiallyOpened || false);
  const ChevronIcon = theme.dir === "ltr" ? IconChevronRight : IconChevronLeft;
  const [outerNavbarPage, setOuterNavbarPage] = useState();
  const handleItemClick = (pageName) => {
    updateCurrentPage2(pageName);
    // updateCurrentPage(pageName);
  };
  const [{ toggle }] = useDisclosure(false);
  const [parentOpened, { toggle: toggleParent }] = useDisclosure(false);
  const [childOpened, { toggle: toggleChild }] = useDisclosure(false);
  const nestedLinks = link?.links?.map((temp, index) => (
    <div
      style={{ width: "100%", height: "200px", border: "2px solid black" }}
      key={index}
    >
      {console.log("temp", "checking5")}
      <Text>jhi</Text>
    </div>
  ));
  const items = (hasLinks ? links : []).map((link, index) => (
    <div style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
      <SidebarItem
        currentPage={currentPage}
        updateCurrentPage={updateCurrentPage}
        link={link}
        index={index}
      ></SidebarItem>
    </div>
    // <div
    //   onClick={() => {
    //     handleItemClick(`${link.label}`);
    //   }}
    // >
    //   <Link to={link.link} className={`${classes.link} `}>
    //     <Text
    //       component="a"
    //       href={link.link}
    //       key={link.label}
    //       onClick={(event) => {
    //         Navigate(link.link);
    //         console.log(link.link, "linkshere");
    //       }}
    //       style={{ color: currentPage === link.label ? "blue" : "black" }}
    //     >
    //       <Box>
    //         <div
    //           style={{
    //             width: "100%",
    //             // border: "2px solid black",
    //             // height: "160px",
    //           }}
    //         >
    //           {link.label}
    //           {/* <Box maxWidth={400} mx="auto">
    //             <Group justify="center" mb={5}>
    //               <Button style={{ width: "100%" }} onClick={toggleParent}>
    //                 {link.label}
    //               </Button>
    //             </Group>

    //               <Box pl={4}>
    //                 {link?.links?.length &&
    //                   link?.links?.map((temp, index) => (
    //                     <div
    //                       style={{
    //                         width: "100%",
    //                         height: "auto",
    //                       }}
    //                     >
    //                       <Collapse in={parentOpened} transitionDuration={400}>
    //                       {temp.label}
    //                       {index}
    //                   </Collapse>
    //                     </div>
    //                   ))}
    //               </Box>
    //           </Box> */}
    //           {/* <DivComponent link={link} index={index}></DivComponent> */}

    //           {/* <Box maw={400} mx="auto">
    //             <Group justify="center" mb={5}>
    //               <Button onClick={toggle}>Toggle content</Button>
    //             </Group>
    //             <Collapse in={opened}>
    //               {" "}
    //               <Text>Testing text</Text>
    //             </Collapse>
    //           </Box> */}
    //         </div>
    //         {/* {link.label} */}
    //         {/* {link?.links?.length ? <Collapse in={opened}>temp</Collapse> : null} */}
    //         {/* {console.log(link?.links, "checking", link.label)} */}
    //         {/* {link?.links?.map((temp) => (
    //           <div>{console.log(temp, "checking2", link.label)}</div>
    //         ))} */}
    //       </Box>
    //     </Text>
    //   </Link>
    // </div>
  ));

  return (
    <>
      <UnstyledButton
        className={` ${classes.control}  `}
        style={{}}
        onClick={() => {
          setOpened((o) => !o);
        }}
      >
        <div style={{}}>
          <Link
            to={link}
            style={{
              "text-decoration": "none",
              // padding: "1rem",
              // margin: "1rem",
              // border: "2px solid black",
              // color: currentPage === label ? "white" : "black",
            }}
          >
            <Group
              position="apart"
              spacing={0}
              className=""
              style={{
                backgroundColor: currentPage2 === label ? "#004AAD" : "",
                color: currentPage2 === label ? "white" : "black",
                borderRadius: "0.3rem",
                borderWidth: "0.5rem",
                paddingTop: "0.5rem",
                paddingBottom: "0.5rem",
                paddingLeft: "0.5rem",
              }}
              onClick={() => handleItemClick(`${label}`)}
            >
              {console.log("outerside", currentPage2)}
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {/* <ThemeIcon variant="light" size={30}>
                  <Icon size={18} />
                </ThemeIcon> */}
                <img
                  src={currentPage2 === label ? image2 : image}
                  alt=""
                  width={32}
                />
                <Box
                  className={` ${classes.linkparent}  `}
                  style={{ color: currentPage2 === label ? "white" : "black" }}
                  ml="md"
                >
                  {label}
                </Box>
              </Box>
              {console.log(links)}
              {hasLinks && (
                <ChevronIcon
                  className={classes.chevron}
                  size={14}
                  stroke={1.5}
                  color={currentPage2 === label ? "white" : "black"}
                  style={{
                    marginRight: "1rem",
                    transform: opened
                      ? `rotate(${theme.dir === "rtl" ? -90 : 90}deg)`
                      : "none",
                  }}
                />
              )}
            </Group>
          </Link>
        </div>
      </UnstyledButton>
      {hasLinks ? <Collapse in={opened}>{items}</Collapse> : null}
    </>
  );
}

const mockdata = {
  label: "Releases",
  icon: IconCalendarStats,
  links: [
    { label: "Upcoming releases", link: "/" },
    { label: "Previous releases", link: "/" },
    { label: "Releases schedule", link: "/" },
  ],
};

export function NavbarLinksGroup() {
  const [selected, setSelected] = useState("/");
  return (
    <Box
      sx={(theme) => ({
        minHeight: 220,
        padding: theme.spacing.md,
        backgroundColor:
          theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.white,
      })}
    >
      <LinksGroup
        {...mockdata}
        selected={selected}
        setSelected={setSelected}
        key="1"
      />
    </Box>
  );
}
