import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "../reducers/reducers";
import rootSaga from "../saga/rootSaga";
import createSagaMiddleware from "@redux-saga/core";
const sagaMiddleware = createSagaMiddleware();
const store = configureStore({
  reducer: rootReducer,
  middleware: () => [sagaMiddleware],
});
sagaMiddleware.run(rootSaga);
export default store;
