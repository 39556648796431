export const PATH = {
  PAGE_404: "/404",
  ROOT: "/",
  HOME: "/home",
  PROFILE: "/profile",
  LOGIN: "/login",
  ADMINDASH: "/admin/dashboard",
  Dashboard_CM: "/cm/dashboard",
  TS: "/ts",
  ADD_TS: "/ts/add",
  EDIT_TS: "/ts/edit",
  ADD_TS_IMAGES: "/ts/upload-images",
  SERIES_VIEW: "/series/view",
  TEST_ADD: "/test/add",
  TESTS: "/tests",
  TEST_VIEW: "/test/view",
  TEST_EDIT: "/test/edit",
  QUES_ADD: "/home/qb/add",
  QUES_ADD_V: "/home/qb/add-variant",
  QUES_LIST: "/home/qb/list",
  QUES_VIEW: "/home/qb/detail",
  QUES_VIEW_V: "/home/qb/detail_v",
  QUES_EDIT: "/home/qb/edit",
  CONTENT_MANAGER_LIST: "/cm",
  CONTENT_MANAGER_VIEW: "/cm/details",
  PAYMENT_CLAIM_REQ: "/pay/claim/req",
  SM_CAT: "/sm/categories",
  SM_CAT_CA: "/sm/cat/ca",
  CA_PDF_LIST: "/sm/ca/pdf/list",
  CA_PDF_ADD: "/sm/ca/pdf/add",
  CA_PDF_VIEW: "/sm/ca/pdf/view",
  CA_PDF_EDIT: "/sm/ca/pdf/edit",
  STORIES: "/stories",
  USERS_LIST: "/users",
  USERS_VIEW: "/user/details",
  TICKET_VIEW: "/ticket/details",
  USERS_ORDER_VIEW: "/user/order/details",
  ORDERS: "/orders",
  TEST_PREVIEW: "/exam/preview/:testid",
  RUN_TEST_PREVIEW: "/exam/preview/start/:testid",
  EXAM_REVIEW: "/exam/review",
  SC_List: "/sc/list",
  SC_Add: "/sc/add",
  SC_View: "/sc/view",
  SC_Edit: "/sc/edit",
  WORK_TASK_VIEW: "/worktask/details",
  WORK_TASK_DETAILED_VIEW: "/worktask/details/detailed",
  CLAIM_REQUEST_VIEW: "/claimRequest/details",
  CM_Tasks: "/cm/tasks",
  CM_Claim_History: "/cm/claimHistory",
  CM_Claim_History_View: "/cm/claimRequest/details",
  CM_FandQ_List: "/cm/FAQ's",
  CM_FandQ_Add: "/cm/FAQ/add",
  CM_FandQ_View: "/cm/FAQ/view",
  CM_Add_Validate_Task: "/cm/task/view",
  Systems: "/config/systems",
  Dropdown: "/config/systems/dropdowns",
  Test_Config: "/config/tests",
  Canvas: "/canvas",
  Exams_Notifications_List: "/notifications/exams/list",
  Exams_Notifications_View: "/notifications/exams/view",
  Entities_List: "/entities",
  Entity_Fill_Details: "/entity/fillDetails",
};
export const ToastType = {
  ERROR: "error",
  SUCCESS: "success",
  INFO: "info",
  WARNING: "warning",
};
