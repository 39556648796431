import {
  Button,
  Container,
  Title,
  Paper,
  Checkbox,
  TextInput,
  PasswordInput,
  Group,
  BackgroundImage,
  Box,
  Text,
} from "@mantine/core";
import { login } from "../actions/auth";
import { ToastContainer, toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { useForm } from "@mantine/form";
import useStyles from "./styles";
import Image_Prefix from "../../src/assets/index";
const Login = (props) => {
  const { classes } = useStyles();
  const form = useForm({
    initialValues: {
      username: "",
      password: "",
    },

    validate: {
      username: (value) => (value.length > 0 ? null : "Invalid username"),
      password: (value) => (value.length >= 3 ? null : "invalid password"),
    },
  });

  let history = useNavigate();
  const { isLoggedIn, token, loginError } = useSelector((state) => state.auth);
  let dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const onSubmit = (data) => {
    setLoading(true);
    dispatch(login(data));
  };
  useEffect(() => {
    const userToken = localStorage.getItem("user_token");
    const userRole = localStorage.getItem("user_role");
    if (isLoggedIn) {
      if (userToken != null) {
        if (userRole == "admin") {
          history("/admin/dashboard", {
            replace: true,
            state: {
              token: token,
            },
          });
        } else if (
          userRole == "content_feeder" ||
          userRole == "content_validator"
        ) {
          history("/cm/dashboard", { replace: true });
        }
      }
    }
    if (loginError) {
      setLoading(false);
    }
  }, [isLoggedIn, loginError]);
  return (
    <Box className="Login-bg">
      <Container
        size={350}
        sx={{
          "@media (min-width: 800px)": {
            position: "relative",
            right: "25%",
            top: "15%",
          },
          "@media (max-width: 600px)": {
            paddingTop: 40,
          },
          left: "25%",
        }}
      >
        <Title
          align="center"
          sx={(theme) => ({
            // fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 900,
            color: theme.colors.brandColor,
            textShadow: "1px 1px #e5e5e5",
          })}
        >
          Affordamic<Text color="white"> CMS Login</Text>
        </Title>
        <Paper
          withBorder
          shadow="md"
          p={30}
          mt={30}
          radius="md"
          className={classes.card}
        >
          <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
            <TextInput
              label=""
              labelProps={{
                screenLeft: "10",
                color: "#fff",
              }}
              radius={0}
              variant="filled"
              placeholder="username or email"
              {...form.getInputProps("username")}
              required
            />
            <PasswordInput
              label=""
              placeholder="Your password"
              required
              mt="md"
              radius={0}
              {...form.getInputProps("password")}
            />
            {loginError && (
              <div style={{ display: "flex", gap: "10px", marginTop: "8px" }}>
                <img src={Image_Prefix.login_error} alt="" />
                <p
                  style={{
                    color: "#FF3434",
                    fontSize: "14px",
                    fontWeight: "400",
                    textAlign: "start",
                    margin: "0",
                  }}
                >
                  {loginError}
                </p>
              </div>
            )}
            {/* <Group position="apart" mt="md">
              <Checkbox label="Remember me" />
            </Group> */}
            <Button fullWidth mt="xl" type="submit" loading={isLoading}>
              Sign In
            </Button>
          </form>
        </Paper>
      </Container>
      <ToastContainer />
    </Box>
  );
};
export default Login;
