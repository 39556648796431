import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  tableBg: {
    backgroundColor: "#eee",
  },
  menuButton: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  menuControl: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    border: 0,
    borderLeft: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white
    }`,
  },
  card: {
    padding: 20,
    boxSizing: "border-box",
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
    textAlign: "center",
  },
  inputField: {
    padding: "0 10px",
    fontSize: 16,
    backgroundColor: "#ffffff",
    border: "1px solid #00000000",
    border: 5,
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
  },
}));
export default useStyles;
