import { Navbar, Group, Code, ScrollArea, createStyles } from "@mantine/core";
import LinksGroup from "./GroupLinks";

import data from "./links";
import { useState, useEffect } from "react";
import useSelectedPage from "../../hooks/useSelectedPage";
import img1 from "../../../src/assets/img/Images/dashboardSvg.svg";
import img2 from "../../../src/assets/img/Images/Vector.png";
import img3 from "../../../src/assets/img/Images/solar_wallet-money-bold.png";
import img4 from "../../../src/assets/img/Images/Vector (1).png";
import profilepic from "../../../src/assets/img/Images/profile pic.png";
import nextlogo from "../../../src/assets/img/Images/nextlogo.png";
import "./styless.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../actions/auth";
import { Menu, Button, Text, rem } from "@mantine/core";
import Image_Prefix from "../../assets";

import {
  IconCalendarStats,
  IconChevronLeft,
  IconChevronRight,
  mmy,
} from "@tabler/icons";
import AuthService from "../../services/api";
import { relativeTimeRounding } from "moment/moment";
const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.white,
    paddingBottom: 0,
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  header: {
    padding: theme.spacing.md,
    paddingTop: 0,
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  links: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
  },

  linksInner: {
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
  },

  footer: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));

export default function NavbarNested(props) {
  const { classes } = useStyles();
  let links_data = data(localStorage.getItem("user_role"));
  console.log(localStorage.getItem("user_role"));
  if (!links_data) {
    links_data = [];
  }
  const [currentPage, setCurrentPage] = useState("");
  const [currentPage2, setCurrentPage2] = useState("");

  const updateCurrentPage = (pageName) => {
    setCurrentPage(pageName);
  };
  const updateCurrentPage2 = (pageName) => {
    setCurrentPage2(pageName);
  };

  const SVGComponent = ({ children }) => (
    <div style={{ width: "24px", marginTop: "0.2rem" }}>{children}</div>
  );

  const links = links_data.map((item, index) => {
    return (
      <LinksGroup
        {...item}
        key={index + 1}
        currentPage={currentPage}
        currentPage2={currentPage2}
        updateCurrentPage={updateCurrentPage}
        updateCurrentPage2={updateCurrentPage2}
      />
    );
  });
  const [sidebarStates, setSidebarStates] = useState(
    Array(links_data?.length)
      .fill(false)
      .map((_, index) => (index === 0 ? true : false))
  );

  const toggleSidebar = (index) => {
    const newSidebarStates = Array(links_data?.length).fill(false);
    newSidebarStates[index] = !sidebarStates[index];
    setSidebarStates(newSidebarStates);
  };
  const [userdata, setuserData] = useState(null);

  const navigate = useNavigate();
  useEffect(() => {
    const id = 138260;
    AuthService.getUserDetail(id).then(
      (response) => {
        console.log(response.data.data);
        if (response && response?.data && response?.data?.data) {
          setuserData(response.data.data);
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }, []);
  const dispatch = useDispatch();

  return (
    <div>
      <Navbar
        // width={{ sm: 250 }}
        // className={classes.navbar}
        style={{
          display: !props.opened ? "flex" : "flex",
          // height: "100vh",
          transition: "all 0.3s ease",
        }}
        width={{ sm: !props.opened ? 80 : 220, overflowX: "visible" }}
        p={"md"}
        onMouseEnter={props.onMouseEnter}
      >
        <Navbar.Section
          className={classes.header}
          style={{
            display: !props.opened ? "none" : "flex",
            // height: "100%",
          }}
        >
          <Group position="apart">
            <Code sx={{ fontWeight: 700 }}>Affordamic</Code>
          </Group>
        </Navbar.Section>

        {props.opened && (
          <>
            <div
              grow="true"
              className={classes.links}
              component={ScrollArea}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                position: "relative",
                overflowX: "visible",
                height: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  overflowX: "visible",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    rowGap: "1rem",
                    alignItems: "center",
                    overflowX: "visible ",
                    paddingTop: "1rem",
                  }}
                >
                  {links_data.map((temp, idx) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          transition: "all 0.3s ease",
                          // overflowX: "visible",
                        }}
                        key={idx}
                      >
                        <div
                          style={{
                            width: "100%",
                            fontSize: "18px",
                            fontWeight: "500",
                            fontFamily: "'Figtree', sans-serif",
                            display: "flex",
                            justifyContent: "space-between",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            alignItems: "center",
                            overflowX: "visible",
                            cursor: "pointer",
                            paddingTop: "0.5rem",
                            paddingBottom: "0.5rem",
                            backgroundColor: sidebarStates[idx]
                              ? "#228BE6"
                              : "",
                            color: sidebarStates[idx] ? "white" : "#0460d8",
                            transition: "all 0.2s ease-in-out",
                          }}
                          onClick={() => {
                            toggleSidebar(idx);
                            navigate(temp?.link);
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              columnGap: "0.5rem",
                              alignItems: "center",
                              overflowX: "visible",
                            }}
                          >
                            <img
                              src={
                                !sidebarStates[idx]
                                  ? temp?.svgblue
                                  : temp?.svgwhite
                              }
                              alt=""
                              width={20}
                            />

                            <div>{temp?.label}</div>
                          </div>
                          {temp?.links && (
                            <div>
                              <IconChevronLeft
                                className={classes.chevron}
                                size={14}
                                stroke={1.5}
                                style={{
                                  marginRight: "1rem",
                                  rotate: sidebarStates[idx]
                                    ? "90deg"
                                    : "270deg",
                                }}
                              />
                            </div>
                          )}
                        </div>

                        {sidebarStates[idx] && temp?.links && (
                          <div
                            style={{
                              display: "flex",
                              marginLeft: "2.6rem",
                              flexDirection: "column",
                              marginTop: "0.5rem",
                              marginBottom: "0.5rem",
                              borderLeft: "2px solid #228BE6",
                              overflowX: "visible",
                              transition: "all 0.5s ",
                            }}
                            className="topidiv"
                          >
                            {temp?.links.map((subtemp, subidx) => {
                              return (
                                <div
                                  style={{ transition: "all 0.5s " }}
                                  className="outerr-div"
                                  onClick={() => navigate(subtemp?.link)}
                                  key={subidx}
                                >
                                  <div>{subtemp?.label}</div>

                                  {subtemp?.links?.length && (
                                    <span>
                                      <div>
                                        {" "}
                                        <IconChevronLeft
                                          className={classes.chevron}
                                          size={15}
                                          stroke={1.5}
                                          style={{ rotate: "180deg" }}
                                        />
                                      </div>
                                    </span>
                                  )}
                                  {subtemp?.links?.length && (
                                    <div className="wraptestt">
                                      <div
                                        className="temp nestedd-div "
                                        onMouseOver={(e) => e.stopPropagation()}
                                      >
                                        {subtemp?.links.map(
                                          (nestemp, nesidx) => {
                                            return (
                                              <div
                                                style={{ position: "relative" }}
                                                className="nestedDiv"
                                                onClick={(e) => {
                                                  navigate(nestemp?.link);
                                                  console.log("herer");
                                                  // window.location.href =
                                                  //   nestemp?.link;
                                                  e.stopPropagation();
                                                }}
                                              >
                                                {nestemp?.label}

                                                <div className="triangle"></div>
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>{" "}
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "10px",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "3px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  columnGap: "6px",
                  alignItems: "center",
                }}
              >
                <div style={{ marginLeft: "-0.6rem" }}>
                  <img src={profilepic} width={50} alt="" />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    color: "#9747FF",
                    width: "100%",
                  }}
                >
                  <div>{userdata?.username}</div>
                  <div>Id:{userdata?.userId}</div>
                </div>
              </div>
              <div>
                <Menu shadow="md" width={90}>
                  <Menu.Target>
                    <img src={nextlogo} alt="" />
                  </Menu.Target>

                  <Menu.Dropdown>
                    <Menu.Item
                      onClick={() => {
                        localStorage.clear();
                        dispatch(logout());
                        navigate("/login");
                      }}
                    >
                      Log Out
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </div>
            </div>
          </>
        )}

        {!props.opened && localStorage.getItem("user_role") === "admin" && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              height: "100vh",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                rowGap: "2rem",
                alignItems: "center",
                flexDirection: "column",
                paddingBotton: "0.5rem",
                marginTop: "40px",
              }}
            >
              <div
                className="transtion"
                onClick={props.toggle}
                style={{ cursor: "pointer" }}
              >
                <img src={Image_Prefix.dashboard_icon_blue} width={20} alt="" />
              </div>
              <div
                className="transtion"
                onClick={props.toggle}
                style={{ cursor: "pointer" }}
              >
                <img src={Image_Prefix.education_blue} width={20} alt="" />
              </div>
              <div
                className="transtion"
                onClick={props.toggle}
                style={{ cursor: "pointer" }}
              >
                <img src={Image_Prefix.billing_blue} width={20} alt="" />
              </div>
              <div
                className="transtion"
                onClick={props.toggle}
                style={{ cursor: "pointer" }}
              >
                <img src={Image_Prefix.key_icon} width={20} alt="" />
              </div>
              <div
                className="transtion"
                onClick={props.toggle}
                style={{ cursor: "pointer" }}
              >
                <img src={Image_Prefix.configuration_blue} width={20} alt="" />
              </div>
              <div
                className="transtion"
                onClick={props.toggle}
                style={{ cursor: "pointer" }}
              >
                <img
                  src={Image_Prefix.sidebar_notification_blue}
                  width={20}
                  alt=""
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <img onClick={props.toggle} width={48} src={profilepic} alt="" />
            </div>
          </div>
        )}
        {!props.opened &&
          (localStorage.getItem("user_role") === "content_validator" ||
            localStorage.getItem("user_role") === "content_feeder") && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                height: "100vh",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  rowGap: "2rem",
                  alignItems: "center",
                  flexDirection: "column",
                  paddingBotton: "0.5rem",
                  marginTop: "40px",
                }}
              >
                <div
                  className="transtion"
                  onClick={props.toggle}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={Image_Prefix.dashboard_icon_blue}
                    width={20}
                    alt=""
                  />
                </div>
                <div className="transtion" onClick={props.toggle}>
                  <img src={Image_Prefix.tasks_icon} width={18} alt="" />
                </div>
                <div className="transtion" onClick={props.toggle}>
                  <img src={Image_Prefix.help_faq_icon} width={20} alt="" />
                </div>
                <div className="transtion" onClick={props.toggle}>
                  <img src={Image_Prefix.rupees_icon} width={20} alt="" />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <img
                  onClick={props.toggle}
                  width={48}
                  src={profilepic}
                  alt=""
                />
              </div>
            </div>
          )}
      </Navbar>
    </div>
  );
}
