import { takeEvery, put } from "redux-saga/effects";
import { GET_TEST_DETAILS, SET_TEST_DETAILS } from "../constants/types";
import apis from "../services/api";
function* getTestDetail(data) {
  console.log("saga", data);
  const res = yield apis.getTestDetails(data.data);
  
  console.warn("res", res);
  yield put({ type: SET_TEST_DETAILS, data: res.data.data });
}

function* examPanelSaga(data) {
  yield takeEvery(GET_TEST_DETAILS, getTestDetail);
}
export default examPanelSaga;
