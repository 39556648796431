import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { NotificationsProvider } from "@mantine/notifications";

import store from "./store/store";
import { ThemeProvider } from "@mui/system";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <MantineProvider
        withGlobalStyles={false}
        withNormalizeCSS={false}
        theme={{
          breakpoints: {
            xs: 500,
            sm: 800,
            md: 1000,
            lg: 1200,
            xl: 1400,
          },
          colors: {
            brandColor: [
              "#ebf3ff",
              "#d4e4fa",
              "#a3c6f7",
              "#6fa7f6",
              "#4a8cf5",
              "#367bf5",
              "#2c72f6",
              "#2161dc",
              "#1756c4",
              "#004aad",
            ],
          },
        }}
      >
        <ModalsProvider>
          <NotificationsProvider>
            <Provider store={store}>
              <App />
            </Provider>
          </NotificationsProvider>
        </ModalsProvider>
      </MantineProvider>
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
