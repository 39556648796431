import React from "react";
import { toast } from "react-toastify";
import { ToastType } from "../constants/paths";
import fileDownload from "js-file-download";
import axios from "axios";
export default function fireToast(
  type,
  message,
  position = toast.POSITION.TOP_RIGHT,
  isAutoClose
) {
  if (type == ToastType.ERROR) {
    if (message === "Session invalidated") {
      localStorage.clear();
      window.location.href = "/";
    }
    if (message instanceof Array) {
      message = message[0];
    }
    toast.error(message, {
      position: position,
      autoClose: isAutoClose ? 1500 : 20000,
    });
  } else if (type == ToastType.SUCCESS) {
    toast.success(message, {
      position: position,
      autoClose: isAutoClose ? 1500 : 20000,
    });
  } else if (type == ToastType.INFO) {
    toast.info(message, {
      position: position,
      autoClose: isAutoClose ? 1500 : 20000,
    });
  } else if (type == ToastType.WARNING) {
    toast.warning(message, {
      position: position,
      autoClose: isAutoClose ? 1500 : 20000,
    });
  }
}
