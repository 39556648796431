import { takeEvery, put } from "redux-saga/effects";
import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGIN_USER,
  SET_TEST_DETAILS,
} from "../constants/types";
import apis from "../services/api";
function* doLogin(data) {
  console.log("login saga", data);
  try {
    const res = yield apis.login(data.data);
    console.warn("res", res);
    localStorage.setItem("user_token", res.data.data.token);
    localStorage.setItem("user_role", res.data.data.role);
    yield put({ type: LOGIN_SUCCESS, data: res.data.data });
  } catch (err) {
    yield put({ type: LOGIN_FAIL, data: err });
  }
}

function* login(data) {
  yield takeEvery(LOGIN_USER, doLogin);
}

export default login;
