import axios from "axios";
import auth from "./authHeaders";
console.log("auth", auth());
// let BASE_URL = "http://localhost:3000/prod/api/v1/";
let BASE_URL = "https://api-staging.affordamic.com/api/v1";
const headers = {
  "Content-Type": "application/json",
  ...auth(),
};

let client = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
  headers: headers,
});
const setMultipart = (isMultipart = false) => {
  if (isMultipart) {
    client = axios.create({
      baseURL: BASE_URL,
      timeout: 30000,
      headers: {
        "Content-Type": "multipart/form-data",
        ...auth(),
      },
    });
  } else {
    client = axios.create({
      baseURL: BASE_URL,
      timeout: 30000,
      headers: {
        "Content-Type": "application/json",
        ...auth(),
      },
    });
  }
};
const getUserRole = () => {
  return localStorage.getItem("user_role");
};
const login = (data) => {
  return client.post("auth/emp-login", data, { headers });
};
const getAllTestSeries = (data) => {
  return client.post("series/admin/getAll", data);
};
const getArrayByName = (name) => {
  return client.get("common/array/getByName", { params: { name } });
};
const addArrayByName = (data) => {
  return client.post("common/array/add", data);
};
const getAllRewardConditions = (params) => {
  return client.get("rewards/getAll", { params });
};
const addSeries = (data) => {
  return client.post("series/addSeries", data);
};
const updateSeries = (data) => {
  return client.post("series/update", data);
};
const deleteSeries = (id) => {
  return client.get("series/deleteTestSeries", { params: { seriesId: id } });
};
const getSeriesDetails = (seriesId) => {
  return client.get("series/get/details", { params: { seriesId } });
};
const uploadSeriesImages = (seriesId, key, file) => {
  const form = new FormData();
  form.append("seriesId", seriesId);
  form.append(key, file);
  return client.post("series/add-images", form);
};
const addTest = (data) => {
  return client.post("series/exam/add", data);
};
const updateTest = (data) => {
  return client.post("series/exam/update", data);
};
const deleteTest = (id) => {
  return client.get("series/exam/delete", { params: { testId: id } });
};
const getAllTests = (data) => {
  return client.post("series/admin/exam/getAll", data);
};
const getTestDetails = (id) => {
  return client.get("series/exam/details", { params: { testId: id } });
};
const getAllStaticContents = (data) => {
  return client.get("static/content/getAll", data);
};
const addStaticContent = (data) => {
  return client.post("static/create/content", data);
};
const viewStaticContent = (id) => {
  return client.get("static/content/get", { params: { contentId: id } });
};
const editStaticContent = (data) => {
  return client.post("static/update/content", data);
};
const deleteStaticContent = (id) => {
  return client.get("static/delete/content", { params: { contentId: id } });
};
const uploadImage = (quesCode, name, key, file) => {
  setMultipart(true);
  let form = new FormData();
  form.append(key, file);
  form.append("questionCode", quesCode);
  form.append("name", name);
  return client.post("ques/getImageUrl", form);
};
const AddQuestion = (data) => {
  setMultipart(false);
  return client.post("/ques/add-new", data);
};
const AddQuestionVarient = (data) => {
  setMultipart(false);
  return client.post("/ques/add-varient", data);
};
const getQuesVarients = (id) => {
  setMultipart(false);
  return client.get("/ques/get-varients", { params: { questionId: id } });
};
const getAllQuestions = (data) => {
  setMultipart(false);
  return client.get("ques/getAll", { params: data });
};
const getAllQuestionPivots = (data) => {
  setMultipart(false);
  return client.get("ques/getQuestionPivots", { params: data });
};
const getQuestionDetails = (id, lang) => {
  return client.get("ques/get/details", {
    params: { id, code: id, lang: lang },
  });
};

const getQuestionMappings = (id) => {
  return client.get("ques/getMapping", { params: { id } });
};
const updateQuestion = (data) => {
  setMultipart(false);
  return client.post("ques/update", data);
};
const deleteQuestion = (id) => {
  setMultipart(false);
  return client.get("ques/delete", { params: { id } });
};
const getAllFeeders = (data) => {
  setMultipart(false);
  return client.get("cfeeders/getAll", { params: data });
};
const getAllValidators = (data) => {
  setMultipart(false);
  return client.get("cvalidators/getAll", { params: data });
};
const addFeeder = (data) => {
  setMultipart(false);
  return client.post("cfeeders/add", data);
};
const updateFeederPassword = (id) => {
  setMultipart(false);
  return client.get("cfeeders/change-password", { params: { userId: id } });
};
const deleteFeeder = (id) => {
  setMultipart(false);
  return client.get("cfeeders/delete", { params: { userId: id } });
};
const updateFeederAccount = (data) => {
  setMultipart(false);
  return client.post("cfeeders/update", data);
};
const changeQuesVerify = (id) => {
  setMultipart(false);
  return client.get("ques/verify-question", { params: { id } });
};
const getEarningStats = (userId) => {
  setMultipart(false);
  return client.get("cfeeders/getStats", { params: { userId } });
};
const claimEarning = (userId) => {
  setMultipart(false);
  return client.get("cfeeders/requestClaim", { params: { userId } });
};
const getClaimRequests = (data) => {
  setMultipart(false);
  return client.get("cfeeders/getClaimRequests", { params: data });
};
const updateClaimRequest = (data) => {
  setMultipart(false);
  return client.post("cfeeders/updateClaimRequest", data);
};
const addValidator = (data) => {
  setMultipart(false);
  return client.post("cvalidators/add", data);
};
const updateValidatorPassword = (id) => {
  setMultipart(false);
  return client.get("cvalidators/change-password", { params: { userId: id } });
};
const deleteValidator = (id) => {
  setMultipart(false);
  return client.get("cvalidators/delete", { params: { userId: id } });
};
const updateValidatorAccount = (data) => {
  setMultipart(false);
  return client.post("cvalidators/update", data);
};
const getAllValidatorQuestions = (data) => {
  setMultipart(false);
  return client.get("cvalidators/getQuestions", { params: data });
};
const getValidatorAccountStats = (data) => {
  setMultipart(false);
  return client.get("cvalidators/getStats", { params: data });
};
const shiftQuestions = (data) => {
  setMultipart(false);
  return client.post("ques/shift", data);
};
const getTestQuestions = (data) => {
  setMultipart(false);
  return client.get("ques/testQuestions", { params: data });
};
const searchQuestions = (data) => {
  setMultipart(false);
  return client.get("ques/search", { params: data });
};
const testQuesMapping = (data) => {
  setMultipart(false);
  return client.post("series/exam/question-mapping", data);
};
const removeQuestionMapping = (data) => {
  setMultipart(false);
  return client.post("series/remove/question-mapping", data);
};
const addTestSection = (data) => {
  setMultipart(false);
  return client.post("series/exam/add-section", data);
};
const removeTestSection = (data) => {
  setMultipart(false);
  return client.post("series/exam/remove-section", data);
};
const getMappedTests = (data) => {
  setMultipart(false);
  return client.get("series/get/series-all-tests", {
    params: { seriesId: data },
  });
};
const addTestToSeries = (data) => {
  setMultipart(false);
  return client.post("series/add-test-to-series", data);
};
const updateTestsToSeries = (data) => {
  setMultipart(false);
  return client.post("series/update-tests-in-series", data);
};
const getCurrentAffairsPdfs = (data) => {
  setMultipart(false);
  return client.get("staticmaterial/current-affairs/pdf/getAll", {
    params: data,
  });
};
const addPdfCA = (data) => {
  setMultipart(true);
  return client.post("staticmaterial/current-affairs/pdf/add", data);
};
const updatePdfCA = (data) => {
  setMultipart(true);
  return client.post("staticmaterial/current-affairs/pdf/update", data);
};
const getPdfDetailsCA = (id) => {
  setMultipart(false);
  return client.get("staticmaterial/current-affairs/pdf/get-details", {
    params: { contentId: id },
  });
};
const togglePdfCA = (id) => {
  setMultipart(false);
  return client.get("staticmaterial/current-affairs/pdf/toggle-active", {
    params: { contentId: id },
  });
};
const deletePdfCA = (id) => {
  setMultipart(false);
  return client.get("staticmaterial/current-affairs/pdf/delete", {
    params: { contentId: id },
  });
};
const getAllStories = (query) => {
  setMultipart(false);
  return client.get("story/getAll", { params: query });
};
const deleteStory = (id) => {
  setMultipart(false);
  return client.get("story/delete", { params: { storyId: id } });
};
const toggleStoryStatus = ({ id, status }) => {
  setMultipart(false);
  return client.get("story/status/update", {
    params: { storyId: id, status: status },
  });
};
const addStory = (data) => {
  setMultipart(true);
  return client.post("story/release", data);
};
const getAdminStats = () => {
  setMultipart(false);
  return client.get("admin/statatics", { params: {} });
};
const getUsers = (params) => {
  setMultipart(false);
  return client.get("user/getAll", { params: params });
};
const getUserDetail = (id) => {
  setMultipart(false);
  return client.get("user/get/detail", { params: { userId: id } });
};
const getUserWallet = (id) => {
  setMultipart(false);
  return client.get("user/wallet/getBalance", { params: { userId: id } });
};
const getUserOrders = (params) => {
  setMultipart(false);
  return client.get("purchase/order/getAll", { params: params });
};
const getUserOrdersDetails = (orderId) => {
  setMultipart(false);
  return client.get("purchase/order/detail", { params: { orderId } });
};
const getUserLoginActivity = (id, offset) => {
  setMultipart(false);
  return client.get("user/recent-logins", { params: { userId: id, offset } });
};
const updateUserWallet = (data) => {
  setMultipart(false);
  return client.post("admin/user/wallet/update", data);
};
const createUserWallet = (id) => {
  setMultipart(false);
  return client.get("admin/user/wallet/create", { params: { userId: id } });
};
const initRefund = (data) => {
  setMultipart(false);
  return client.get("/purchase/order/cancel", { params: data });
};
const updateUser = (data) => {
  setMultipart(false);
  return client.post("/user/update/profile", data);
};
const verifyExplanation = (id) => {
  setMultipart(false);
  return client.get("ques/verify-explanation", { params: { id } });
};
const getTestReport = (id, trackerId) => {
  return client.get("series/exam/report", {
    params: { testId: id, trackerId: trackerId },
  });
};

const getSectionQuestion = (data) => {
  return client.post("series/exam/get-ques", data);
};

const getTestTracker = (id) => {
  return client.get("/series/exam/get/tracker", { params: { testId: id } });
};
const updateQuestionState = (trackerId, quesId, state, answerId) => {
  return client.get("/series/exam/update/testQuesState", {
    params: {
      trackerId: trackerId,
      quesId: quesId,
      state: state,
      answerId: answerId,
    },
  });
};

const getinitializeTest = (id, defaultLang, isTnCAaccepted) => {
  return client.get("/series/exam/initTest", {
    params: {
      testId: id,
      defaultLang: defaultLang,
      isTnCAaccepted: isTnCAaccepted,
    },
  });
};

const getTestSolutions = (params) => {
  return client.get("/series/exam/solutions", { params: params });
};

const submitTest = (data) => {
  return client.post("/series/exam/submit", { trackerId: data });
};

const getAllContentManagers = (params) => {
  setMultipart(false);
  return client.get("/user/cm/getAll", { params: params });
};
const getContentManagerDetails = (id) => {
  setMultipart(false);
  return client.get("/user/cm/get-profile", { params: { userId: id } });
};
const deleteContentManager = (id) => {
  setMultipart(false);
  return client.get("/user/cm/delete", { params: { userId: id } });
};
const updateContentManagerPassword = (id) => {
  setMultipart(false);
  return client.get("user/cm/change-password", { params: { userId: id } });
};
const addContentManager = (data) => {
  setMultipart(false);
  return client.post("/user/cm/add", data);
};
const updateContentManager = (data) => {
  setMultipart(false);
  return client.post("/user/cm/update", data);
};
const getAllContentManagerWorkTasks = (params) => {
  setMultipart(false);
  return client.get("/user/cm/getAll/work-task", { params: params });
};
const updateContentManagerRole = (data) => {
  setMultipart(false);
  return client.post("/user/cm/update-access-role", data);
};
const addContentManagerWorkTask = (data) => {
  setMultipart(false);
  return client.post("/user/cm/add/work-task", data);
};
const updateWorkTaskStatus = (params) => {
  setMultipart(false);
  return client.get("/user/cm/update/work-task-status", { params: params });
};

const getTopicWiseReport = (params) => {
  return client.get("/series/exam/report/topic-wise", { params: params });
};

const getQuestionSolutionDetails = (params) => {
  return client.get("series/exam/report/question-details", { params: params });
};

const getWorkTaskDetails = (id) => {
  setMultipart(false);
  return client.get("/user/cm/get/work-task", { params: { workTaskId: id } });
};

const deleteWorkTask = (id) => {
  setMultipart(false);
  return client.get("/user/cm/delete/work-task", {
    params: { workTaskId: id },
  });
};

const updateWorkTask = (data) => {
  setMultipart(false);
  return client.post("/user/cm/edit/work-task", data);
};

const getAllTickets = (params) => {
  setMultipart(false);
  return client.get("/admin/i/complaints/get-all", { params: params });
};

const getTicketDetails = (id) => {
  setMultipart(false);
  return client.get("/admin/i/complaints/ticket-details", {
    params: { complaintId: id },
  });
};

const performTicketAction = (data) => {
  setMultipart(true);
  return client.post("/admin/i/complaints/perform-action", data);
};

const updateTicketStatus = (params) => {
  setMultipart(false);
  return client.get("/admin/i/complaints/update/ticket-status", {
    params: params,
  });
};

const getAllClaimRequests = (params) => {
  setMultipart(false);
  return client.get("/user/cm/get/claim-requests", { params: params });
};

const getClaimRequestDetails = (id) => {
  setMultipart(false);
  return client.get("/user/cm/get/claim-request-detail", {
    params: { requestId: id },
  });
};

const updateClaimRequestStatus = (data) => {
  setMultipart(false);
  return client.post("/user/cm/update/claim-request-status", data);
};

const getCMClaimHistory = (params) => {
  setMultipart(false);
  return client.get("/user/cm/get/claim-history", { params: params });
};

const getCMTasks = (params) => {
  setMultipart(false);
  return client.get("/user/cm/client/all-work-task", { params: params });
};

const CMAcceptRejectTask = (params) => {
  setMultipart(false);
  return client.get("/user/cm/client/task/acceptOrReject", { params: params });
};

const getCMClaimableTaskList = () => {
  setMultipart(false);
  return client.get("/user/cm/get/claimable-tasks");
};

const getCMDashboardStatus = () => {
  setMultipart(false);
  return client.get("/user/cm/client/dashboard-stats");
};

const requestCMNewClaim = (data) => {
  setMultipart(false);
  return client.post("/user/cm/requestClaim", data);
};

const getEarningDashboardDetails = (id) => {
  setMultipart(false);
  return client.get("/user/cm/get/earning-stats", { params: { userId: id } });
};

const getCMFandQ = (params) => {
  setMultipart(false);
  return client.get("/admin/i/complaints/get-all", { params: params });
};

const addCMTicket = (data) => {
  setMultipart(true);
  return client.post("/admin/i/complaints/create-ticket", data);
};

const getCMTaskDetails = (params) => {
  setMultipart(false);
  return client.get("/user/cm/client/work-task", { params: params });
};

const validateCMQuestionUnit = (data) => {
  setMultipart(false);
  return client.post("/user/cm/client/task/validate-unit", data);
};

const updateQuestionStatusLevelWise = (data) => {
  setMultipart(false);
  return client.post("/ques/update/QuestionVerifyStatus", data);
};

const getAllDropdowns = () => {
  setMultipart(false);
  return client.get("/common/array/getAll");
};

const deleteArrayByName = (params) => {
  setMultipart(false);
  return client.get("/common/array/remove", { params: params });
};

const addArray = (data) => {
  setMultipart(false);
  return client.post("/common/array/add-multiple", data);
};

const updateArray = (data) => {
  setMultipart(false);
  return client.post("/common/array/update", data);
};

const getAllTestConfigs = (params) => {
  setMultipart(false);
  return client.get("/admin/test-config/getAll", { params: params });
};

const addTestConfig = (data) => {
  setMultipart(false);
  return client.post("/admin/create/test-config", data);
};

const editTestConfig = (data) => {
  setMultipart(false);
  return client.post("/admin/update/test-config", data);
};

const deleteTestConfig = (id) => {
  setMultipart(false);
  return client.get("/admin/delete/test-config", { params: { configId: id } });
};

const getTestConfigDetails = (id) => {
  setMultipart(false);
  return client.get("/admin/get/test-config", { params: { configId: id } });
};

const updateStory = (data) => {
  setMultipart(true);
  return client.post("/story/update", data);
};

const changeTrackerLanguage = (data) => {
  setMultipart(false);
  return client.post("/series/exam/update-tracker-lang", data);
};

const getLineChartData = (params) => {
  setMultipart(false);
  return client.get("/series/exam/report/graphs", { params: params });
};

const getLeadershipBoard = (id) => {
  setMultipart(false);
  return client.get("/series/exam/leadershipboard", { params: { testId: id } });
};

const getAllTestTrackers = (id) => {
  setMultipart(false);
  return client.get("/series/exam/get/all-trackers", {
    params: { testId: id },
  });
};

const getAllExamsNotifications = (params) => {
  return client.get("/web/all/eb-notifications", { params: params });
};

const getExamNotificationsDetails = (id) => {
  return client.get("/web/get/eb-notification", {
    params: { notificationId: id },
  });
};

const getAllEntities = () => {
  return client.get("/web/get/entity-list-all");
};

const addExamNotification = (data) => {
  setMultipart(true);
  return client.post("/web/create/eb-notification", data);
};

const updateExamNotification = (data) => {
  setMultipart(true);
  return client.post("/web/update/eb-notification", data);
};

const deleteExamNotification = (id) => {
  return client.get("/web/delete/eb-notification", {
    params: { notificationId: id },
  });
};

const updateExamNotificationStatus = (data) => {
  setMultipart(false);
  return client.post("/web/update/eb-notification/status", data);
};

const addEntity = (data) => {
  setMultipart(false);
  return client.post("/web/add/entity", data);
};

const updateEntity = (data) => {
  setMultipart(false);
  return client.post("/web/update/entity", data);
};

const deleteEntity = (id) => {
  return client.get("/web/delete/entityListItem", { params: { id } });
};

export default {
  login,
  getUserRole,
  getAllTestSeries,
  getArrayByName,
  addArrayByName,
  getAllRewardConditions,
  addSeries,
  updateSeries,
  deleteSeries,
  setMultipart,
  uploadSeriesImages,
  getSeriesDetails,
  addTest,
  updateTest,
  deleteTest,
  getAllTests,
  getTestDetails,
  uploadImage,
  AddQuestion,
  getAllQuestions,
  getAllQuestionPivots,
  getQuestionDetails,
  getQuestionMappings,
  updateQuestion,
  deleteQuestion,
  changeQuesVerify,
  getAllFeeders,
  getAllValidators,
  addFeeder,
  updateFeederPassword,
  deleteFeeder,
  updateFeederAccount,
  getEarningStats,
  claimEarning,
  getClaimRequests,
  updateClaimRequest,
  addValidator,
  updateValidatorPassword,
  deleteValidator,
  updateValidatorAccount,
  getAllValidatorQuestions,
  getValidatorAccountStats,
  AddQuestionVarient,
  getQuesVarients,
  shiftQuestions,
  getTestQuestions,
  searchQuestions,
  testQuesMapping,
  removeQuestionMapping,
  addTestSection,
  removeTestSection,
  getMappedTests,
  addTestToSeries,
  updateTestsToSeries,
  getCurrentAffairsPdfs,
  addPdfCA,
  updatePdfCA,
  getPdfDetailsCA,
  togglePdfCA,
  deletePdfCA,
  getAllStories,
  deleteStory,
  toggleStoryStatus,
  addStory,
  getAdminStats,
  getUsers,
  getUserDetail,
  getUserWallet,
  getUserOrders,
  getUserOrdersDetails,
  getUserLoginActivity,
  updateUserWallet,
  createUserWallet,
  initRefund,
  updateUser,
  verifyExplanation,
  getAllStaticContents,
  addStaticContent,
  viewStaticContent,
  getSectionQuestion,
  editStaticContent,
  deleteStaticContent,
  getTestReport,
  getTestTracker,
  updateQuestionState,
  getinitializeTest,
  getTestSolutions,
  submitTest,
  getTopicWiseReport,
  getQuestionSolutionDetails,
  getAllContentManagers,
  getContentManagerDetails,
  deleteContentManager,
  updateContentManagerPassword,
  addContentManager,
  updateContentManager,
  getAllContentManagerWorkTasks,
  updateContentManagerRole,
  addContentManagerWorkTask,
  updateWorkTaskStatus,
  getWorkTaskDetails,
  deleteWorkTask,
  updateWorkTask,
  getAllTickets,
  getTicketDetails,
  performTicketAction,
  updateTicketStatus,
  getAllClaimRequests,
  getClaimRequestDetails,
  updateClaimRequestStatus,
  getCMClaimHistory,
  getCMTasks,
  CMAcceptRejectTask,
  getCMClaimableTaskList,
  getCMDashboardStatus,
  requestCMNewClaim,
  getEarningDashboardDetails,
  getCMFandQ,
  addCMTicket,
  getCMTaskDetails,
  validateCMQuestionUnit,
  updateQuestionStatusLevelWise,
  getAllDropdowns,
  addArray,
  deleteArrayByName,
  updateArray,
  getAllTestConfigs,
  addTestConfig,
  editTestConfig,
  deleteTestConfig,
  getTestConfigDetails,
  updateStory,
  changeTrackerLanguage,
  getLineChartData,
  getLeadershipBoard,
  getAllTestTrackers,
  getAllExamsNotifications,
  getExamNotificationsDetails,
  getAllEntities,
  addExamNotification,
  updateExamNotification,
  deleteExamNotification,
  updateExamNotificationStatus,
  addEntity,
  updateEntity,
  deleteEntity,
};
