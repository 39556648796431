import React, { lazy, Suspense } from "react";
import { Route, Routes as Switch, Outlet } from "react-router-dom";
import { PATH } from "../constants/paths";
import Loading from "../components/loading";
import AuthenticatedGuard from "../guards/authenticatedGuard";
import Pager from "../pages/Home";
import routeList from "./routesList";
const Login = lazy(() => import("../pages/Login"));

export default function LoginRoutes() {
  return (
    <Switch>
      <Route exact path={PATH.ROOT} element={<Login />} />

      <Route
        exact
        path={PATH.LOGIN}
        element={
          <>
            <Suspense fallback={<Loading />}>
              <Login />
            </Suspense>
          </>
        }
      />
      {routeList.map((route) => (
        <Route
          exact
          path={route.path}
          key="parent"
          element={route.isProtected ? <AuthenticatedGuard /> : <Outlet />}
        >
          {!route.pageExternal ? (
            <Route
              exact
              key="child"
              path={route.path}
              element={
                <>
                  <Suspense fallback={<Loading />}>
                    <Pager page={route.page} />
                  </Suspense>
                </>
              }
            />
          ) : (
            <Route
              exact
              key="child"
              path={route.path}
              element={
                <>
                  <Suspense fallback={<Loading />}>{route.page}</Suspense>
                </>
              }
            />
          )}
        </Route>
      ))}
    </Switch>
  );
}
