import React from "react";
import Navbar from "../components/Sidebar/SidenavBar";
import Header from "../components/Header/header";
import { AppShell } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { ToastContainer } from "react-toastify";

const Home = ({ page }) => {
  const [opened, { toggle, open }] = useDisclosure(false);

  const handleMouseEnter = () => {
    open();
  };

  return (
    <>
      <AppShell
        padding="sm"
        navbar={
          <Navbar
            opened={opened}
            toggle={toggle}
            onMouseEnter={handleMouseEnter}
          />
        }
        header={<Header opened={opened} toggle={toggle} />}
        style={{ backgroundColor: "#ECECEC" }}
      >
        {page}
      </AppShell>
      <ToastContainer />
    </>
  );
};
export default Home;
