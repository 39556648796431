import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import Login from "../pages/Login";

const ProtectedRoute = () => {
  const auth = useSelector((state) => state.auth);
  console.log("auth", auth);
  return auth.isLoggedIn ? <Outlet /> : <Login />;
};

export default ProtectedRoute;
