import { SET_TEST_DETAILS } from "../constants/types";
const initialState = {};
export default function (state = initialState, action) {
  const { type, data } = action;
  switch (type) {
    case SET_TEST_DETAILS:
      console.log("reducer called", action);
      return { data };
    default:
      return state;
  }
}
