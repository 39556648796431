import { PATH } from "../constants/paths";
import { lazy } from "react";

const PAGE_404 = lazy(() => import("../components/404"));

const Dashboard_CM = lazy(() => import("../pages/Dashboard-CM"));
const AdminDashboard = lazy(() => import("../pages/Dashboard-ADMIN"));
const TS = lazy(() => import("../pages/Test-Series/TestSeries"));
const ADD_TS = lazy(() => import("../pages/Test-Series/Add"));
const ADD_TS_IMAGES = lazy(() => import("../pages/Test-Series/AddImages"));
const SERIES_VIEW = lazy(() => import("../pages/Test-Series/View"));
const SERIES_EDIT = lazy(() => import("../pages/Test-Series/Edit"));
const ADD_TEST = lazy(() => import("../pages/Exam/Add"));
const TESTS = lazy(() => import("../pages/Exam/TestsList"));
const TEST_VIEW = lazy(() => import("../pages/Exam/QuestionMapping"));
const TEST_EDIT = lazy(() => import("../pages/Exam/Edit"));

const SC_List = lazy(() => import("../pages/StaticContent/List"));
const SC_Add = lazy(() => import("../pages/StaticContent/Add"));
const SC_View = lazy(() => import("../pages/StaticContent/View"));
const SC_Edit = lazy(() => import("../pages/StaticContent/Edit"));

const QUES_ADD = lazy(() => import("../pages/QuestionBank/Add"));
const QUES_ADD_VARIENT = lazy(() =>
  import("../pages/QuestionBank/Add-Varient")
);
const QUES_LIST = lazy(() => import("../pages/QuestionBank/List"));

const QUES_VIEW = lazy(() => import("../pages/QuestionBank/View"));
const QUES_EDIT = lazy(() => import("../pages/QuestionBank/Edit"));
const CONTENT_MANAGER_LIST = lazy(() =>
  import("../pages/Accounts/ContentManagers/List")
);
const CONTENT_MANAGER_VIEW = lazy(() =>
  import("../pages/Accounts/ContentManagers/View")
);
const PAYMENT_CLAIM_REQ = lazy(() => import("../pages/Payment/ClaimRequests"));
const SM_CAT = lazy(() => import("../pages/StaticMaterial/Categories"));
const SM_CAT_CA = lazy(() =>
  import("../pages/StaticMaterial/CurrentAffairs/Categories")
);
const CA_PDF_LIST = lazy(() =>
  import("../pages/StaticMaterial/CurrentAffairs/CA-PDFs/list")
);
const CA_PDF_ADD = lazy(() =>
  import("../pages/StaticMaterial/CurrentAffairs/CA-PDFs/add")
);
const CA_PDF_VIEW = lazy(() =>
  import("../pages/StaticMaterial/CurrentAffairs/CA-PDFs/view")
);
const CA_PDF_EDIT = lazy(() =>
  import("../pages/StaticMaterial/CurrentAffairs/CA-PDFs/edit")
);
const STORIES = lazy(() => import("../pages/Stories/storyboard"));
const USERS_LIST = lazy(() => import("../pages/Accounts/Users/list"));
const USERS_VIEW = lazy(() => import("../pages/Accounts/Users/view"));
const TICKET_VIEW = lazy(() =>
  import("../pages/Accounts/ContentManagers/TicketView")
);
const USERS_ORDER_VIEW = lazy(() =>
  import("../pages/Accounts/Users/orderDetails")
);
const LIST_ORDERS = lazy(() => import("../pages/Orders/list"));
const TEST_PREVIEW_CMS = lazy(() =>
  import("../pages/TestSeriesPreviewInternal/TestInstruction")
);
const EXAM_PORTAL = lazy(() =>
  import("../pages/TestSeriesPreviewInternal/ExamPortal/PortalMain")
);
const WORK_TASK_VIEW = lazy(() =>
  import("../pages/Accounts/ContentManagers/WorkTaskView")
);
const WORK_TASK_DETAILED_VIEW = lazy(() =>
  import("../pages/Accounts/ContentManagers/WorkTaskDetailedView")
);
const CLAIM_REQUEST_VIEW = lazy(() =>
  import("../pages/Accounts/ContentManagers/ClaimRequestView")
);
const CM_Tasks = lazy(() => import("../pages/CM-Panel/Tasks/Tasks"));
const CM_Claim_History = lazy(() =>
  import("../pages/CM-Panel/ClaimHistory/ClaimHistory")
);
const CM_Claim_History_View = lazy(() =>
  import("../pages/CM-Panel/ClaimHistory/ClaimHistoryView")
);
const CM_FandQ_List = lazy(() => import("../pages/CM-Panel/FandQ/List"));
const CM_FandQ_Add = lazy(() => import("../pages/CM-Panel/FandQ/Add"));
const CM_FandQ_View = lazy(() => import("../pages/CM-Panel/FandQ/View"));
const CM_Add_Validate_Task = lazy(() =>
  import("../pages/CM-Panel/Tasks/AddValidateTask")
);
const Systems = lazy(() => import("../pages/Systems/System"));
const Dropdown = lazy(() => import("../pages/Systems/Dropdown"));
const Test_Config = lazy(() => import("../pages/Systems/TestConfig"));
const Canvas = lazy(() => import("../pages/Canvas"));
const EXAM_REVIEW = lazy(() => import("../pages/Report/Report"));
const Exams_Notifications_List = lazy(() =>
  import("../pages/Notifications/Exams/List")
);
const Exams_Notifications_View = lazy(() =>
  import("../pages/Notifications/Exams/View")
);
const Entities_List = lazy(() => import("../pages/Entities/List"));
const Entity_Fill_Details = lazy(() => import("../pages/Entities/FillDetails"));
const routes = [
  {
    path: PATH.ADMINDASH,
    key: PATH.ADMINDASH,
    isProtected: true,
    page: <AdminDashboard />,
  },
  {
    path: PATH.TS,
    key: PATH.TS,
    isProtected: true,
    page: <TS />,
  },
  {
    path: PATH.ADD_TS,
    key: PATH.ADD_TS,
    isProtected: true,
    page: <ADD_TS />,
  },
  {
    path: PATH.ADD_TS_IMAGES,
    key: PATH.ADD_TS_IMAGES,
    isProtected: true,
    page: <ADD_TS_IMAGES />,
  },
  {
    path: PATH.TEST_ADD,
    key: PATH.TEST_ADD,
    isProtected: true,
    page: <ADD_TEST />,
  },
  {
    path: PATH.TESTS,
    key: PATH.TESTS,
    isProtected: true,
    page: <TESTS />,
  },
  {
    path: PATH.QUES_ADD,
    key: PATH.QUES_ADD,
    isProtected: true,
    page: <QUES_ADD />,
  },
  {
    path: PATH.QUES_ADD_V,
    key: PATH.QUES_ADD_V,
    isProtected: true,
    page: <QUES_ADD_VARIENT />,
  },
  {
    path: PATH.QUES_LIST,
    key: PATH.QUES_LIST,
    isProtected: true,
    page: <QUES_LIST />,
  },
  {
    path: PATH.QUES_VIEW,
    key: PATH.QUES_VIEW,
    isProtected: true,
    page: <QUES_VIEW />,
  },
  {
    path: PATH.QUES_EDIT,
    key: PATH.QUES_Edit,
    isProtected: true,
    page: <QUES_EDIT />,
  },
  {
    path: PATH.CONTENT_MANAGER_LIST,
    key: PATH.CONTENT_MANAGER_LIST,
    isProtected: true,
    page: <CONTENT_MANAGER_LIST />,
  },
  {
    path: PATH.CONTENT_MANAGER_VIEW,
    key: PATH.CONTENT_MANAGER_VIEW,
    isProtected: true,
    page: <CONTENT_MANAGER_VIEW />,
  },
  {
    path: PATH.PAYMENT_CLAIM_REQ,
    key: PATH.PAYMENT_CLAIM_REQ,
    isProtected: true,
    page: <PAYMENT_CLAIM_REQ />,
  },
  {
    path: PATH.Dashboard_CM,
    key: PATH.Dashboard_CM,
    isProtected: true,
    page: <Dashboard_CM />,
  },
  {
    path: PATH.TEST_VIEW,
    key: PATH.TEST_VIEW,
    isProtected: true,
    page: <TEST_VIEW />,
  },
  {
    path: PATH.TEST_EDIT,
    key: PATH.TEST_EDIT,
    isProtected: true,
    page: <TEST_EDIT />,
  },
  {
    path: PATH.SERIES_VIEW,
    key: PATH.SERIES_VIEW,
    isProtected: true,
    page: <SERIES_VIEW />,
  },
  {
    path: PATH.EDIT_TS,
    key: PATH.EDIT_TS,
    isProtected: true,
    page: <SERIES_EDIT />,
  },
  {
    path: PATH.SM_CAT,
    key: PATH.SM_CAT,
    isProtected: true,
    page: <SM_CAT />,
  },
  {
    path: PATH.SM_CAT_CA,
    key: PATH.SM_CAT_CA,
    isProtected: true,
    page: <SM_CAT_CA />,
  },
  {
    path: PATH.CA_PDF_LIST,
    key: PATH.CA_PDF_LIST,
    isProtected: true,
    page: <CA_PDF_LIST />,
  },
  {
    path: PATH.CA_PDF_ADD,
    key: PATH.CA_PDF_ADD,
    isProtected: true,
    page: <CA_PDF_ADD />,
  },
  {
    path: PATH.CA_PDF_VIEW,
    key: PATH.CA_PDF_VIEW,
    isProtected: true,
    page: <CA_PDF_VIEW />,
  },
  {
    path: PATH.CA_PDF_EDIT,
    key: PATH.CA_PDF_EDIT,
    isProtected: true,
    page: <CA_PDF_EDIT />,
  },
  {
    path: PATH.STORIES,
    key: PATH.STORIES,
    isProtected: true,
    page: <STORIES />,
  },
  {
    path: PATH.USERS_LIST,
    key: PATH.USERS_LIST,
    isProtected: true,
    page: <USERS_LIST />,
  },
  {
    path: PATH.USERS_VIEW,
    key: PATH.USERS_VIEW,
    isProtected: true,
    page: <USERS_VIEW />,
  },
  {
    path: PATH.TICKET_VIEW,
    key: PATH.TICKET_VIEW,
    isProtected: true,
    page: <TICKET_VIEW />,
  },
  {
    path: PATH.USERS_ORDER_VIEW,
    key: PATH.USERS_ORDER_VIEW,
    isProtected: true,
    page: <USERS_ORDER_VIEW />,
  },
  {
    path: PATH.ORDERS,
    key: PATH.ORDERS,
    isProtected: true,
    page: <LIST_ORDERS />,
  },
  {
    path: PATH.ORDERS,
    key: PATH.ORDERS,
    isProtected: true,
    page: <LIST_ORDERS />,
  },
  {
    path: PATH.TEST_PREVIEW,
    key: PATH.TEST_PREVIEW,
    isProtected: true,
    pageExternal: true,
    page: <TEST_PREVIEW_CMS />,
  },
  {
    path: PATH.RUN_TEST_PREVIEW,
    key: PATH.RUN_TEST_PREVIEW,
    isProtected: true,
    pageExternal: true,
    page: <EXAM_PORTAL />,
  },
  {
    path: localStorage.getItem("user_token") != null ? "*" : "/nopage",
    key: "none",
    isProtected: false,
    page: localStorage.getItem("user_token") != null ? <PAGE_404 /> : "",
  },
  {
    path: PATH.SC_List,
    key: PATH.SC_List,
    isProtected: true,
    page: <SC_List />,
  },
  {
    path: PATH.SC_Add,
    key: PATH.SC_Add,
    isProtected: true,
    page: <SC_Add />,
  },
  {
    path: PATH.SC_View,
    key: PATH.SC_View,
    isProtected: true,
    page: <SC_View />,
  },
  {
    path: PATH.SC_Edit,
    key: PATH.SC_Edit,
    isProtected: true,
    page: <SC_Edit />,
  },
  {
    path: PATH.EXAM_REVIEW,
    key: PATH.EXAM_REVIEW,
    isProtected: true,
    pageExternal: true,
    page: <EXAM_REVIEW />,
  },
  {
    path: PATH.WORK_TASK_VIEW,
    key: PATH.WORK_TASK_VIEW,
    isProtected: true,
    page: <WORK_TASK_VIEW />,
  },
  {
    path: PATH.WORK_TASK_DETAILED_VIEW,
    key: PATH.WORK_TASK_DETAILED_VIEW,
    isProtected: true,
    pageExternal: true,
    page: <WORK_TASK_DETAILED_VIEW />,
  },
  {
    path: PATH.CLAIM_REQUEST_VIEW,
    key: PATH.CLAIM_REQUEST_VIEW,
    isProtected: true,
    page: <CLAIM_REQUEST_VIEW />,
  },
  {
    path: PATH.CM_Tasks,
    key: PATH.CM_Tasks,
    isProtected: true,
    page: <CM_Tasks />,
  },
  {
    path: PATH.CM_Claim_History,
    key: PATH.CM_Claim_History,
    isProtected: true,
    page: <CM_Claim_History />,
  },
  {
    path: PATH.CM_Claim_History_View,
    key: PATH.CM_Claim_History_View,
    isProtected: true,
    page: <CM_Claim_History_View />,
  },
  {
    path: PATH.CM_FandQ_List,
    key: PATH.CM_FandQ_List,
    isProtected: true,
    page: <CM_FandQ_List />,
  },
  {
    path: PATH.CM_FandQ_Add,
    key: PATH.CM_FandQ_Add,
    isProtected: true,
    page: <CM_FandQ_Add />,
  },
  {
    path: PATH.CM_FandQ_View,
    key: PATH.CM_FandQ_View,
    isProtected: true,
    page: <CM_FandQ_View />,
  },
  {
    path: PATH.CM_Add_Validate_Task,
    key: PATH.CM_Add_Validate_Task,
    isProtected: true,
    pageExternal: true,
    page: <CM_Add_Validate_Task />,
  },
  {
    path: PATH.Systems,
    key: PATH.Systems,
    isProtected: true,
    page: <Systems />,
  },
  {
    path: PATH.Dropdown,
    key: PATH.Dropdown,
    isProtected: true,
    page: <Dropdown />,
  },
  {
    path: PATH.Test_Config,
    key: PATH.Test_Config,
    isProtected: true,
    page: <Test_Config />,
  },
  {
    path: PATH.Canvas,
    key: PATH.Canvas,
    isProtected: true,
    pageExternal: true,
    page: <Canvas />,
  },
  {
    path: PATH.Exams_Notifications_List,
    key: PATH.Exams_Notifications_List,
    isProtected: true,
    page: <Exams_Notifications_List />,
  },
  {
    path: PATH.Exams_Notifications_View,
    key: PATH.Exams_Notifications_View,
    isProtected: true,
    page: <Exams_Notifications_View />,
  },
  {
    path: PATH.Entities_List,
    key: PATH.Entities_List,
    isProtected: true,
    page: <Entities_List />,
  },
  {
    path: PATH.Entity_Fill_Details,
    key: PATH.Entity_Fill_Details,
    isProtected: true,
    page: <Entity_Fill_Details />,
  },
];
export default routes;
