import { combineReducers } from "redux";
import loginReducer from "./LoginReducers";
import messageReducer from "./messageReducer";
import examPortalReducer from "./ExamPortalReducer";

const rootReducer = combineReducers({
  auth: loginReducer,
  message: messageReducer,
  eportal: examPortalReducer,
});
export default rootReducer;
